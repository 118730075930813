import { Template } from '@canalplus/sdk-hodor';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ButtonShapes } from '../../../constants/button';
import { TemplateTypes } from '../../../constants/templateTypes';
import I18n from '../../../lang';
import { isDarkModeSelector } from '../../../store/slices/application-selectors';
import LoadableLiveGridFavoritesContainer from '../../../templates/LiveGridFavorites';
import Button from '../../Button/Button';
import LiveGridModal from '../LiveGridModal/LiveGridModal';
import styles from './LiveGridEditFavoritesChannels.css';

export enum LiveGridFavoritesButtonTypes {
  DEFAULT = 'DEFAULT',
  EMPTY_STATE = 'EMPTY_STATE',
}

export type LiveGridFavoritesButtonProps = {
  isDark?: boolean;
  openModal?: () => void;
  type?: LiveGridFavoritesButtonTypes;
};

function LiveGridFavoritesButton({
  isDark = false,
  openModal = () => null,
  type = LiveGridFavoritesButtonTypes.DEFAULT,
}: LiveGridFavoritesButtonProps): JSX.Element {
  const { t } = I18n.useTranslation();

  switch (type) {
    case LiveGridFavoritesButtonTypes.EMPTY_STATE:
      return (
        <Button
          className={styles.liveGridEditFavoritesButton}
          color={TemplateTypes.SECONDARY}
          handler={() => openModal()}
          shape={ButtonShapes.ROUND}
          text={t('LiveGrid.favorites.editButtonEmptyState')}
          e2e="liveGridFavoritesButton__empty"
        />
      );
    case LiveGridFavoritesButtonTypes.DEFAULT:
    default:
      return (
        <Button
          className={classNames(styles.liveGridEditFavoritesButton, styles['liveGrid--focus'])}
          color={isDark ? TemplateTypes.DARK : TemplateTypes.PRIMARY_INVERTED}
          handler={() => openModal()}
          shape={ButtonShapes.ROUND}
          text={t('LiveGrid.favorites.editButton')}
          e2e="liveGridFavoritesButton__default"
        />
      );
  }
}

export type LiveGridEditFavoritesChannelsProps = {
  isTvDevice?: boolean;
  liveGridPageTracking?: Tracking;
  refetchLiveGridRequest?: () => void;
  type?: LiveGridFavoritesButtonTypes;
};

function LiveGridEditFavoritesChannels({
  isTvDevice = false,
  liveGridPageTracking,
  refetchLiveGridRequest = () => null,
  type = LiveGridFavoritesButtonTypes.DEFAULT,
}: LiveGridEditFavoritesChannelsProps): JSX.Element {
  const isDark = useSelector(isDarkModeSelector);
  return (
    <LiveGridModal
      isTvDevice={isTvDevice}
      modalContent={
        <LoadableLiveGridFavoritesContainer
          from={Template.LiveGrid}
          liveGridPageTracking={liveGridPageTracking}
          refetchLiveGridRequest={refetchLiveGridRequest}
        />
      }
      triggerModalElement={<LiveGridFavoritesButton isDark={isDark} type={type} />}
    />
  );
}

export default LiveGridEditFavoritesChannels;
